<template>
  <div class="email-verification">
    <div class="headline">
      {{ title }}
    </div>
  </div>
</template>

<script>
import { Api } from 'lib/api/instance'

export default {
  data: () => ({
    error: '',
    success: false,
  }),

  computed: {
    title ({ error, success }) {
      if (success) {
        return 'Ваша почта подтверждена'
      }
      if (error) {
        return error
      }
      return 'Подтверждаем почту ..'
    },
  },

  created () {
    this.verificate()
  },

  methods: {
    async verificate () {
      const url = 'email/verification'
      const token = this.$route.query.token

      if (token) {
        try {
          await Api.post(url, { token })
          this.success = true
          setTimeout(() => {
            window.location = '/'
          }, 2000)
        }
        catch {
          this.error = 'Произошла ошибка'
        }
      }
      else {
        this.error = 'Отсутствует токен'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.email-verification {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
</style>
